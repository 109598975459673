<template>
  <div class="container">
    <h1>Editar usuário {{ user.name }}</h1>

    <v-progress-circular indeterminate v-if="loading"></v-progress-circular>

    <v-form @submit.prevent="updateUser" ref="formUpdateUser">
      <v-text-field
        label="Nome"
        :rules="[(v) => !!v || 'Preencha este campo']"
        v-model="user.name"
      ></v-text-field>

      <v-text-field
        label="E-mail"
        v-model="user.email"
        :rules="[(v) => !!v || 'Preencha este campo']"
      ></v-text-field>

      <v-select
        :items="[
          { text: 'Usuário', value: 'user' },
          { text: 'Gerente', value: 'manager' },
          { text: 'Admin', value: 'admin' },
          { text: 'Super Admin', value: 'superAdmin' },
        ]"
        item-text="text"
        item-value="value"
        v-model="user.role"
        label="Tipo de usuário"
      ></v-select>

      <v-btn color="primary" class="mx-2" type="submit" :disabled="loading"
        >Salvar</v-btn
      >
      <v-btn
        color="error"
        class="mx-2"
        @click="$router.push({ name: 'users.index' })"
        >Voltar</v-btn
      >
    </v-form>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getUser();
  },
  data() {
    return {
      loading: false,
      user: {
        name: "",
        email: "",
        role: "",
      },
    };
  },
  methods: {
    async updateUser() {
      const form = this.$refs.formUpdateUser;

      if (form.validate()) {
        this.loading = true;
        try {
          await this.$http.put(`api/users/${this.user?.id}`, this.user);
          form.resetValidation();
          this.$toast.success("Salvo");
        } catch (error) {
          this.$toast.error("Erro ao salvar");

          if (error.response) {
            let errors = Object.entries(error.response.data.errors);

            if (errors) {
              errors.forEach((e) => {
                this.$toast.error(e[1][0]);
              });
            }
          }
        }
        this.loading = false;
      }
    },
    async getUser() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(
          `api/users/${this.$route.params.id}`
        );
        this.user = data;
      } catch (error) {
        this.$toast.error("Erro ao carregar usuário");
      }
      this.loading = false;
    },
  },
};
</script>
